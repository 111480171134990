<template>
  <div>
    <aRow class="haya-panels mb-20" :gutter="20">
      <a-col :span="6">
        <div class="box">
          <div class="title"></div>
          <div class="total">{{ invoice.value | formatPricePtBr }}</div>
          <div class="txt"></div>
          <div class="footer">Valor da fatura</div>
        </div>
      </a-col>
      <a-col :span="6">
        <div class="box">
          <div class="title"></div>
          <div class="total">
            {{ invoice.to_pay_value | formatPricePtBr }}
          </div>
          <div class="txt"></div>
          <div class="footer">Valor à pagar</div>
        </div>
      </a-col>
      <a-col :span="6">
        <div class="box">
          <div class="title"></div>
          <div class="total">
            {{ invoice.incoming_value | formatPricePtBr }}
          </div>
          <div class="txt"></div>
          <div class="footer">Valor a Receber</div>
        </div>
      </a-col>
      <a-col :span="6">
        <div class="box">
          <div class="title"></div>
          <div class="total">
            {{ invoice.balance_value | formatPricePtBr }}
          </div>
          <div class="txt"></div>
          <div class="footer">Saldo</div>
        </div>
      </a-col>
    </aRow>

    <InvoicePaxesTable
      :contractList="contractList"
      :loading="loadingContractList"
    />
  </div>
</template>

<script>
import formatThings from "@/mixins/general/formatThings";
import contractMixins from "@/components/contracts/mixins/contractMixins";
import InvoicePaxesTable from "@/components/finances/sections/InvoicePaxesTable.vue";
import generateInvoicesCalcsAndRules from "@/components/finances/mixins/generateInvoicesCalcsAndRules";

export default {
  name: "InvoiceModal",
  props: {
    invoice: Object,
  },
  components: { InvoicePaxesTable },
  mixins: [formatThings, contractMixins, generateInvoicesCalcsAndRules],
  data() {
    return {
      contractList: [],
      loadingContractList: false,
      columns: [
        {
          title: "Venda",
          scopedSlots: { customRender: "sale_id" },
          width: 70,
        },
        {
          title: "Loc.",
          dataIndex: "locator",
          key: "locator",
          width: 90,
        },
        {
          title: "Viajante",
          scopedSlots: { customRender: "traveller_name" },
          width: 120,
        },
        {
          title: "A receber",
          key: "incomings",
          width: 120,
          scopedSlots: { customRender: "incomings" },
        },
        {
          title: "A pagar",
          key: "to_pay",
          width: 120,
          scopedSlots: { customRender: "to_pay" },
        },
        {
          title: "Saldo",
          key: "balance",
          width: 120,
          scopedSlots: { customRender: "balance" },
        },
        {
          title: "Bilhete",
          key: "ticket_value",
          width: 120,
          scopedSlots: { customRender: "ticket_value" },
        },
        {
          title: "Tx. Embarque",
          key: "ticket_tax_value",
          width: 120,
          scopedSlots: { customRender: "ticket_tax_value" },
        },
        {
          title: "Taxa extra",
          width: 120,
          scopedSlots: { customRender: "extra_fee_value" },
        },
      ],
    };
  },
  mounted() {
    this.loadingContractList = true;
    this.$http
      .get(
        `/contract-meta/list?page=1&per_page=100&order=desc&order-by=id&info_name=invoice_id&info_value=${this.invoice.id}`
      )
      .then(({ data }) => {
        this.contractList = data.data.map((item) => {
          return item.contract;
        });
      })
      .finally(() => (this.loadingContractList = false));
  },
};
</script>

<style lang="sass">
.generate-invoices-table td
  padding: 4px 3px 4px 3px !important
.generate-invoices-table thead tr th
  padding: 4px 3px 4px 3px !important

.generate-invoices-table
  .ant-table-pagination
    margin-bottom: 0 !important
    padding: 0
</style>

<style lang="sass" scoped>
.invoice-value
  font-size: 16px
  font-weight: 600
  letter-spacing: -0.5px
</style>
